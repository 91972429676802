import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import TextInput from "../components/form/TextInput"
import { useDispatch, useSelector } from "react-redux"
import { 
    selectContactFirstName,
    selectContactLastName,
    selectContactPhone,
    selectContactEmail,
    selectContactAddress,
    selectContactCity,
    selectContactState,
    selectContactZip,
    setContactFirstName,
    setContactLastName,
    setContactPhone,
    setContactEmail,
    setContactAddress,
    setContactCity,
    setContactState,
    setContactZip,
    sendContactRequest,
    selectIsFormComplete
 } from "../redux/contactSlice"


function ContactPage() {
    const firstName = useSelector(selectContactFirstName)
    const lastName = useSelector(selectContactLastName)
    const phone = useSelector(selectContactPhone)
    const email = useSelector(selectContactEmail)
    const address = useSelector(selectContactAddress)
    const city = useSelector(selectContactCity)
    const state = useSelector(selectContactState)
    const zip = useSelector(selectContactZip)
    const isFormComplete = useSelector(selectIsFormComplete)

    const dispatch = useDispatch()

    return (
        <MainLayoutWrapper 
            lastUrl="/address-search" 
            nextUrl="/contact-success"
            nextUrlText="Submit"
            advanceDisabled={!isFormComplete}
            handleNext={() => dispatch(sendContactRequest({
                firstName,
                lastName,
                phone,
                email,
                address,
                city,
                state,
                zip
            }))}
        >
            <OneColumnLeft 
                title="Please type your contact information below" 
                mobileTitle="Please type your contact information below"
                description="Just a small hiccup! Please input your contact information below. Our team will work to locate your account and reach out to you to wrap up the process. We appreciate your understanding!"
                mobileDescription="Just a small hiccup! Please input your contact information below. Our team will work to locate your account and reach out to you to wrap up the process. We appreciate your understanding!"
                padding="pt-24 md:pt-36"
            >
                {/* create a two column */}
                <div className="hidden md:block">
                    <div className="grid grid-cols-4 gap-4 mb-6">
                        <TextInput value={firstName} onChange={(e) => dispatch(setContactFirstName(e.target.value))} placeholder="First Name" size={'col-span-2 h-12'} />
                        <TextInput value={lastName} onChange={(e) => dispatch(setContactLastName(e.target.value))} placeholder="Last Name" size={'col-span-2 h-12'} />
                    </div>
                    <div className="grid grid-cols-4 gap-4 mb-6">
                        <TextInput value={phone} onChange={(e) => dispatch(setContactPhone(e.target.value))} placeholder="Phone Number" size={'col-span-2 h-12'} />
                        <TextInput value={email} onChange={(e) => dispatch(setContactEmail(e.target.value))} placeholder="Email Address" size={'col-span-2 h-12'} />
                    </div>
                    <div className="grid grid-cols-4 gap-4 mb-6">
                        <TextInput value={address} onChange={(e) => dispatch(setContactAddress(e.target.value))} placeholder="Address" size={'col-span-4 h-12'} />
                    </div>
                    <div className="grid grid-cols-4 gap-4 mb-6">
                        <TextInput value={city} onChange={(e) => dispatch(setContactCity(e.target.value))} placeholder="City" size={'col-span-2 h-12'} />
                        <TextInput value={state} onChange={(e) => dispatch(setContactState(e.target.value))} placeholder="State" size={'col-span-1 h-12'} />
                        <TextInput value={zip} onChange={(e) => dispatch(setContactZip(e.target.value))} placeholder="Zip" size={'col-span-1 h-12'} />
                    </div>
                </div>
                {/* create a mobile version where every input has it's own line */}
                <div className="flex flex-col gap-y-2 md:hidden px-4 w-full">
                    <TextInput value={firstName} onChange={(e) => dispatch(setContactFirstName(e.target.value))} placeholder="First Name" size={'h-12 w-full mb-2'} />
                    <TextInput value={lastName} onChange={(e) => dispatch(setContactLastName(e.target.value))} placeholder="Last Name" size={'h-12 w-full mb-2'} />
                    <TextInput value={phone} onChange={(e) => dispatch(setContactPhone(e.target.value))} placeholder="Phone Number" size={'h-12 w-full mb-2'} />
                    <TextInput value={email} onChange={(e) => dispatch(setContactEmail(e.target.value))} placeholder="Email Address" size={'h-12 w-full mb-2'} />
                    <TextInput value={address} onChange={(e) => dispatch(setContactAddress(e.target.value))} placeholder="Address" size={'h-12 w-full mb-2'} />
                    <TextInput value={city} onChange={(e) => dispatch(setContactCity(e.target.value))} placeholder="City" size={'h-12 w-full mb-2'} />
                    <TextInput value={state} onChange={(e) => dispatch(setContactState(e.target.value))} placeholder="State" size={'h-12 w-full mb-2'} />
                    <TextInput value={zip} onChange={(e) => dispatch(setContactZip(e.target.value))} placeholder="Zip" size={'h-12 w-full mb-2'} />
                </div>
                    
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default ContactPage;