import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import TextInput from "../components/form/TextInput"
import NavLink from "../components/misc/NavLink"
import { useDispatch, useSelector } from "react-redux"
import { selectWarrantyId, setWarrantyId, updateWarrantyID } from "../redux/formSlice"
import checkValidWarrantyId from "../components/validators/checkValidWarrantyId"
import useReloadAccount from "../redux/useReloadAccount"

function WarrantyIdPage() {
    const warrantyId = useSelector(selectWarrantyId);
    const dispatch = useDispatch();
    const warrantyIdCheck = checkValidWarrantyId(warrantyId);
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper 
            lastUrl="/account-search" 
            nextUrl="/account-details" 
            isLoading={loading} 
            handleNext={() => dispatch(updateWarrantyID(warrantyId))}
            advanceDisabled={!warrantyIdCheck.isOk}
        >
            <OneColumnLeft 
                title="Great! We found your account. What is the warranty ID?" 
                mobileTitle="Account found! What is the warranty ID?"
                description="Please enter the short warranty ID code (this is a different code than your account number). It should be near the bottom of your letter, doorhanger or postcard. If you can't find it, click the I can't find it button below."
                mobileDescription="Please enter the short warranty ID code (this is a different code than your account number). It should be near the bottom of your letter, doorhanger or postcard."
            >
                <TextInput value={warrantyId} onChange={(e) => dispatch(setWarrantyId(e.target.value))} />
                {!warrantyIdCheck.isOk && warrantyId ? <p className="text-red-500 text-sm mt-2">{warrantyIdCheck.msg} </p> : null}
                <NavLink location="/account-details" className="mt-4">I can't find it</NavLink>
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default WarrantyIdPage;