import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import TextInput from "../components/form/TextInput"
import { useDispatch, useSelector } from "react-redux"
import { selectEmail, setEmail, updateEmail, selectName } from "../redux/formSlice"
import checkIsValidEmail from "../components/validators/checkIsValidEmail"
import useReloadAccount from "../redux/useReloadAccount"

function EmailPage() {
    const email = useSelector(selectEmail);
    const dispatch = useDispatch();
    const emailCheck = checkIsValidEmail(email);
    const name = useSelector(selectName);
    const title = name ? `Nice to Meet You, ${name}! What's a good email for you?` : "What's a good email for you?";
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper
            lastUrl="/name" 
            nextUrl="/phone" 
            isLoading={loading}
            handleNext={() => dispatch(updateEmail(email))}
            advanceDisabled={!emailCheck.isOk}
        >
            <OneColumnLeft 
                title={title}
                mobileTitle={title}
                description="We'll need an email to attach to your profile to finish setting up your program"
                mobileDescription="We'll need an email to attach to your profile to finish setting up your program"
            >
                <TextInput value={email} onChange={(e) => dispatch(setEmail(e.target.value))} />
                {!emailCheck.isOk && email ?  <p className="text-red-500 text-sm mt-2">{emailCheck.msg}</p> : null}
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default EmailPage;