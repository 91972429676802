import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reloadSession, selectIsAccountFound, selectIsReloadFailed, setAccountError } from './accountSlice';
import { useNavigate } from 'react-router-dom';

function useReloadAccount(page, generateError, errorText) {
    const dispatch = useDispatch();
    const isAccountFound = useSelector(selectIsAccountFound)
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAccountFound) {
            dispatch(reloadSession()).then((action) => {
                if (action.type === reloadSession.rejected.type) {
                    if (!page) return null;
                    if (generateError) {
                        dispatch(setAccountError(errorText))
                    }
                    navigate(page)
                } else {
                    if (action.payload?.last_page) {
                        navigate(`/${action.payload.last_page}`)
                    }
                    // happy path (navigate to the correct page in the flow)
                }
            })
        }
    }, [dispatch, isAccountFound, navigate]);

}

export default useReloadAccount;