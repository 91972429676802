import confetti from '../../assets/confetti.png'

function CallOut({ children, className="", type="confetti", width="160px" }) {
    return (
        <div className={`flex justify-between bg-offWhite rounded-md py-1 px-3 font-bold gap-x-3 ${className}`} style={{width: width}}>
            <span className='text-sm text-lightGreen'>{children}</span>
            {type === "confetti" && <img src={confetti} alt="confetti" className="w-5 h-5" />}
        </div>
    )
}

export default CallOut;