import React, { useState, useEffect } from "react";

function SingleSelectOption({ children, selected, handleClick = [], width = "300px", value = null }) {
    let hex = selected ? "#FFC107" : "#ffffff"
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const itemWidth = isMobile ? '100%' : width;

    const checkScreenWidth = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', checkScreenWidth);
        return () => window.removeEventListener('resize', checkScreenWidth); // Cleanup on unmount
    }, []);

    const handleClickActions = () => {
        handleClick.forEach((action) => {
            action();
        });
    };

    return (
        <div className="font-sans md:w-48 w-full h-10 cursor-pointer hover:opacity-80 mb-4 relative" style={{ borderColor: hex, width: itemWidth }} onClick={handleClickActions}>
            <div className="h-10 border border-1 bg-primary absolute z-0 rounded-md w-full"></div>
            <div className="h-10 border border-1 bg-white bg-opacity-10 absolute z-10 rounded-md w-full" style={{ borderColor: hex }}></div>
            <div className="h-10 max-h-36 overflow-y-scroll relative z-20 flex items-center px-4 font-sans justify-between w-full text-sm md:text-md" style={{ color: hex }}>
                {children}
            </div>
        </div>

    );
}

export default SingleSelectOption;
