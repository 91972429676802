function TextInput({ value = "", onChange = () => { }, size="normal", placeholder="", type="text"}) {
    let styles = "border-b-2 border-gray-400 bg-black bg-opacity-40 text-white placeholder-grey-300 focus:outline-none px-3";
    // width is controlled by .text-input class in frontend/src/index.css

    if (type === "date") {
        styles = "border-b-2 border-gray-600 bg-white bg-opacity-40 text-black placeholder-grey-300 focus:outline-none px-3 filter-invert";  // Add the filter-invert class to styles if type is date
    }

    if(size == 'normal')  {
        return (
            <input
                type={type}
                className={styles + ' text-input'}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        );
    } else {
        return (
            <input
                type={type}
                className={`${size} ${styles}`}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        )
    }
    
}

export default TextInput;
