import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

/**
 * NavButton component represents a button element wrapped in a Link component for navigation.
 * @param {Object} props - The component props.
 * @param {string} props.url - The URL to navigate to when the button is clicked.
 * @param {Function} [props.onClick=()=>{}] - Optional callback function to execute when the button is clicked.
 * @param {boolean} [props.disabled=false] - Indicates whether the button should be disabled.
 * @param {boolean} [props.isSolid=true] - Indicates whether the button should be solid or transparent.
 * @returns {JSX.Element} The rendered NavButton component.
 */
function NavButton({ children, url, onClick = null, disabled = false, isSolid = true, size = "normal", isDeemphasized = false }) {
    const [isLoading, setIsLoading] = useState(false);

    let coloring = isSolid ? "bg-yellowSecondary  border-2 border-yellowSecondary" : "bg-transparent hover:bg-yellowSecondary border-2 border-yellowSecondary";
    if (isDeemphasized) {
        coloring = "bg-gray-400 border-2 border-gray-400";
    }
    let sizeStyles = size == 'normal' ? ' text-sm  w-40 md:w-48' : 'w-full px-4 py-2 text-md'
    let paddingStyles = isLoading ? 'px-4 py-1' : 'py-2 px-3 md:text-md md:py-2 md:px-6'

    const navigate = useNavigate();
    const handleClick = async () => {
        if(onClick) {
            setIsLoading(true);
            await onClick().unwrap();
            setIsLoading(false);
        }
        if (url[0] == '/') {
            navigate(url);
        } else {
            window.location.href = url;
        }
    }

    if (!disabled) {
        return (
            (
                <span onClick={handleClick} className={size == 'normal' ? "" : "w-full"}>
                    <button
                        disabled={disabled}
                        className={`text-white ${sizeStyles} font-bold rounded  hover:opacity-90 ${coloring} ${paddingStyles}`}
                    >
                        {isLoading ? <CircularProgress size={23} color={'inherit'} /> : children}
                    </button>
                </span>
            )
        );
    } else {
        return (
            <span onClick={() => {}} className={size == 'normal' ? "" : "w-full"}>
                <button
                    onClick={onClick}
                    disabled={disabled}
                    className={`text-white ${sizeStyles} font-bold rounded opacity-40 border-white border-2 ${paddingStyles}`}
                    style={{'backgroundColor': "#86814A"}}
                >
                    {isLoading ? <CircularProgress size={23} color={'inherit'} /> : children}
                </button>
            </span>
        )
    }
}

export default NavButton;
