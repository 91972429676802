import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import TwoColumn from "../components/layout/TwoColumn";
import WoodTreat from '../assets/woodtreat.jpg'
import WoodTreatMobile from '../assets/woodtreat_mobile.jpg'
import SoilTreat from '../assets/soiltreat.jpg'
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { selectIsWoodtreat } from "../redux/accountSlice";
import useReloadAccount from "../redux/useReloadAccount";

function InformationPageOne() {
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isWoodtreat = useSelector(selectIsWoodtreat)
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper headline="Termite Program Registration" hideBottom={true} isLoading={loading}>
            {isWoodtreat 
            ? (<TwoColumn
                imageUrl={isMobile ? WoodTreatMobile : WoodTreat}
                title="Here's what we did!"
                buttonText="Next"
                buttonUrl="/info-text-2"
            >
                <p className="mb-4">Your thoughtful builder commissioned us to apply a preventative termite treatment to the woodwork in your home. This protective measure stops termites from tunneling underground and munching on your home's wooden structures.</p>
                <p>The yellow arrow in the image indicates the borate treatment we performed. In fact, if you look closely, you might spot the green dye from our treatment in your basement or garage. That's your home's first line of defense against termites!</p>
            </TwoColumn>)
            : (<TwoColumn
                imageUrl={SoilTreat}
                title="Here's what we did!"
                buttonText="Next"
                buttonUrl="/info-text-2"
                >
                <p className="mb-4">Your thoughtful builder commissioned us to apply a preventative termite treatment to the soil around your home. This protective measure stops termites from tunneling underground and munching on your home's wooden structures.</p>
                </TwoColumn>)
            }
        </MainLayoutWrapper>
    )
}

export default InformationPageOne