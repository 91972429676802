import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import TextInput from "../components/form/TextInput"
import NavLink from "../components/misc/NavLink"
import WavingEmoji from "../assets/waving"
import { selectAccountNumber, setAccountNumber, fetchAccountByAccountNumber, selectAccountErrorMessage } from "../redux/accountSlice"
import { useSelector, useDispatch } from "react-redux"
import Warning from "../components/misc/Warning"
import checkValidAccountNum from "../components/validators/checkValidAccoutNum"
import { useMediaQuery } from "react-responsive"

function AccountSearchPage() {
    const accountNumber = useSelector(selectAccountNumber);
    const dispatch = useDispatch();
    const errorMessage = useSelector(selectAccountErrorMessage);
    const accountCheck = checkValidAccountNum(accountNumber);
    const isMobile = useMediaQuery({ maxWidth: 600 });

    return (
        <MainLayoutWrapper headline="Termite Program Registration" lastUrl="/" nextUrl={"/account-loading?account_num=" + accountNumber} isLoading={false} advanceDisabled={!accountCheck.isOk}>
            {(errorMessage && !isMobile) && <Warning>{errorMessage}</Warning>}
            <OneColumnLeft 
                title="Hey there! Let's start by finding your account"
                mobileTitle="Let's find your account!"
                description="First up, let's locate your account. Enter your six-digit account number (you'll find it at the bottom of our letter, door hanger, or packet) into the search bar below. Struggling to find the account number? No worries, click 'I can't find it: Search By Address Instead' at the bottom."
                mobileDescription="Enter the six-digit number at the bottom of our letter, door hanger, or packet you received."
                emoji={<WavingEmoji />}
            >
                <TextInput value={accountNumber} onChange={(e) => dispatch(setAccountNumber(e.target.value))} />
                <NavLink location="/address-search" className="mt-4">I can't find it: Search By Address Instead</NavLink>
                {!accountCheck.isOk && accountNumber ? <p className="text-red-500 text-sm mt-2">{accountCheck.msg} </p> : null}
                {(errorMessage && isMobile) && <Warning isMobile={true}>{errorMessage}</Warning>}
                
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default AccountSearchPage;