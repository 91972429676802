import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import SingleSelectContainer from "../components/form/SingleSelectContainer"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectUpgradeLater, setUpgradeLater, updateUpgradeLater } from "../redux/formSlice"
import useReloadAccount from "../redux/useReloadAccount"

function DelayPage() {
    const [decision, setDecision] = useState("yes")
    const url = decision === 'yes'  ? "/complete-maybe" : "/complete-no"
    const dispatch = useDispatch();
    const value = useSelector(selectUpgradeLater);
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper 
            headline="Termite Program Registration" 
            nextUrl={url} 
            lastUrl="/offer" 
            isLoading={loading}
            handleNext={() => dispatch(updateUpgradeLater(value))}
        >
            <OneColumnLeft 
                title="Take Your Time - We'll Hold onto Your Free Trial" 
                mobileTitle="Take Your Time - We'll Hold onto Your Free Trial"
                description="Just settling in? No problem! We'll hold onto your 3 month free trial for 14 days. This gives you some extra time to think about upgrading your plan."
                mobileDescription="Just settling in? No problem! We'll hold onto your 3 month free trial for 14 days. This gives you some extra time to think about upgrading your plan."
            >
                <SingleSelectContainer 
                    width="400px"
                    selectedValue={value}
                    options={[
                        { "value": true, "text": "Yes, please give me time to think about it", handleClick: () => dispatch(setUpgradeLater(true)) },
                        { "value": false, "text": "No thanks, I don't want this", handleClick: () => dispatch(setUpgradeLater(false))},
                    ]}
                />
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default DelayPage