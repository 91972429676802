const isProduction = process.env.NODE_ENV === 'production';
const mode = isProduction ? 'production' : 'development';
let config = {};
if (mode == 'development') {
    config.server = 'http://localhost:8000';
    config.client = 'http://localhost:3000';
} else if (mode == 'production') {
    config.server = "https://warranty.bettertermite.com";
    config.client = "https://warranty.bettertermite.com";
} else {
    throw new Error('Invalid NODE_ENV');
}

module.exports = config;