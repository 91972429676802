import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountByAccountNumber } from "./accountSlice";
import config from "../config";

export const fetchSession = createAsyncThunk(
    "session/fetchSession",
    async () => {
        const response = await fetch(
            `${config.server}/api/create-session/`,
            { 'credentials': 'include'}
        );

        if (!response.ok) {
            throw new Error("Something went wrong");
        } else {
            const data = await response.json();
            return data;
        }
    }
);

export const getCsrftoken = createAsyncThunk(
    "session/getCsrftoken",
    async () => {
        const response = await fetch(
            `${config.server}/api/get-csrf/`,
            { 'credentials': 'include'}
        );

        if (!response.ok) {
            throw new Error("Something went wrong");
        } else {
            const data = await response.json();
            return data;
        }
    }
);

const sessionSlice = createSlice({
    name: "session",
    initialState: {
        session: {},
        isSessionLoading: false,
        isSessionError: false,
        sessionErrorMessage: "",
    },
    reducers: {
    },
    extraReducers: {
        [fetchSession.pending]: (state, action) => {
            state.isSessionLoading = true;
            state.isSessionError = false;
            state.sessionErrorMessage = "";
        },
        [fetchSession.fulfilled]: (state, action) => {
            state.isSessionLoading = false;
            state.session = action.payload;
        },
        [fetchSession.rejected]: (state, action) => {
            state.isSessionLoading = false;
            state.isSessionError = true;
            state.sessionErrorMessage = action.error.message;
        },
        [getCsrftoken.pending]: (state, action) => {
            state.isSessionLoading = true;
            state.isSessionError = false;
            state.sessionErrorMessage = "";
        },
        [getCsrftoken.fulfilled]: (state, action) => {
            state.isSessionLoading = false;
            state.session = action.payload;
        },
        [getCsrftoken.rejected]: (state, action) => {
            state.isSessionLoading = false;
            state.isSessionError = true;
            state.sessionErrorMessage = action.error.message;
        },
    },
});

export const { } = sessionSlice.actions;
export const selectSession = (state) => state.session.session;
export const selectIsSessionLoaded = (state) => Object.keys(state.session.session).length > 0;
export const selectHasAccountNum = (state) => state.session.account_num?.length > 0;
export const selectAccountNumFromSession = (state) => state.session.account_num;
export default sessionSlice.reducer;