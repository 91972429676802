import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import SingleSelectContainer from "../components/form/SingleSelectContainer"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useReloadAccount from "../redux/useReloadAccount"

function MediaSelectionPage() {
    const [direction, setDirection] = useState(null)
    const url = direction === 'text'  ? "/info-text-1" : "/video"
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper isLoading={loading} nextUrl={url} advanceDisabled={direction == null}>
            <OneColumnLeft 
                title="Would you prefer video or text?" 
                mobileTitle="Would you prefer video or text?"
            >
                <SingleSelectContainer 
                    selectedValue={direction}
                    options={[
                        { "value": "text", "text": "Text", handleClick: () => setDirection("text") },
                        { "value": "video", "text": "Video", handleClick: () => setDirection("video") },
                    ]}
                />
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default MediaSelectionPage