import { useEffect, useState } from "react";
import SingleSelectOption from "./SingleSelectOption";

function SingleSelectContainer({ options, selectedValue=null, width="300px" }) {
    const [selected, setSelected] = useState(null);

    useEffect(() => { setSelected(null) }, []);
    useEffect(() => { setSelected(selectedValue) }, [selectedValue]);

    return (
        <div className="max-h-96 overflow-y-scroll w-full">
            {options.map((option, index) => {
                return (
                    <SingleSelectOption
                        key={index}
                        selected={selected === option.value}
                        handleClick={[() => setSelected(option.value), option.handleClick]}
                        width={width}
                    >
                        {option.text}
                    </SingleSelectOption>
                );
            })}
        </div>
    );
}

export default SingleSelectContainer;