import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import { useState } from "react"
import TextArea from "../components/form/TextArea"
import { useDispatch, useSelector } from "react-redux"
import { setQuestions, selectQuestions, updateQuestions } from "../redux/formSlice"

function QuestionsPage() {
    const dispatch = useDispatch();
    const questions = useSelector(selectQuestions)

    return (
        <MainLayoutWrapper 
            headline="Termite Program Registration" 
            nextUrl="/name" 
            isLoading={false}
            handleNext={() => dispatch(updateQuestions(questions))}
        >
            <OneColumnLeft 
                title="Any Questions?" 
                mobileTitle="Questions?"
                description="Type any questions you have below for our team. Then, we'll proceed with the registration process!"
                mobileDescription="Type any questions you have below for our team. Then, we'll proceed with the registration process!"
            >
                <TextArea
                    value={questions}
                    onChange={(e) => dispatch(setQuestions(e.target.value))}
                />
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default QuestionsPage;