import FloatingChatIcon from "../misc/FloatingChatIcon";
import NavButton from "./navButton";
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function MainLayoutWrapper({ children, headline = "Termite Program Registration", nextUrl = "", lastUrl = "", lastUrlText = "Back", nextUrlText = "Next", advanceDisabled = false, isLoading = false, hideBottom = false, handleNext = null, handlePrev = null, isPrevDeemphasized = false, isNextDeemphasized = false }) {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="App bg-primary w-full h-screen flex flex-col">
            <div className="flex h-16 bg-darkGreen items-center lg:justify-center py-2 absolute w-full">
                <h1 className='text-white text-lg  md:text-2xl text-center w-full font-semibold'>{headline}</h1>
            </div>
            {
                isLoading && (
                    <div className="flex flex-col items-center justify-center h-screen">
                        <div className="flex flex-col items-center justify-center">
                            <CircularProgress color="inherit" style={{ 'width': '100px', 'height': '100px', color: '#c2b51e', marginBottom: '20px' }} />
                        </div>
                    </div>
                )
            }
            {!isLoading && (<div className="min-h-full md:h-full bg-primary">
                <div className="flex-1 min-h-full md:h-full bg-primary overflow-y-scroll">
                    {children}
                </div>
                {!hideBottom && (<div className="h-24 md:h-24 bg-darkGreen fixed bottom-0 z-10 w-full">
                    <div className="flex justify-center pt-2 gap-x-4">
                        {lastUrl && <NavButton url={lastUrl} handlePrev={handlePrev} isDeemphasized={isPrevDeemphasized}>{lastUrlText}</NavButton>}
                        {nextUrl && <NavButton url={nextUrl} disabled={advanceDisabled} onClick={handleNext} isLoading={true} isDeemphasized={isNextDeemphasized}>{nextUrlText}</NavButton>}
                    </div>
                </div>)}
            </div>

            )}
            {/* <FloatingChatIcon /> */}
        </div>
    );
}

export default MainLayoutWrapper;
