import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import NavLink from "../components/misc/NavLink"
import WavingEmoji from "../assets/waving"
import AddressSearchInput from "../components/form/AddressSearchInput"
import { useSelector, useDispatch } from "react-redux"
import { selectAddressSearch, setAddress } from "../redux/accountSlice"

function AddressSearchPage() {
    const address = useSelector(selectAddressSearch);
    const dispatch = useDispatch();

    const handleAddressChange = (newAddress) => {
        dispatch(setAddress(newAddress));
    }

    return (
        <MainLayoutWrapper 
            lastUrl="/account-search" 
            nextUrl={"/address-search-results?search=" + address} 
        >
            <OneColumnLeft 
                title="No worries. Let's try searching by address" 
                mobileTitle="Let's try searching by address"
                description="No stress! Let's try locating your account using your address. Simply type in your address below. If you're still unable to find it, click 'Contact Me - I can't find my address in the system' for our team to assist you further."
                mobileDescription="Enter your address below. If you're still unable to find it, click 'Contact Me - I can't find my address in the system' for our team to assist you further."
                emoji={<WavingEmoji />}
            >
                <AddressSearchInput setAddress={handleAddressChange} value={address} onChange={(e) => dispatch(setAddress(e.target.value))} />
                <NavLink location="/contact" className="mt-4">Contact Me - I can't find my address in the system</NavLink>
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default AddressSearchPage;