export default function checkValidWarrantyId(warrantyID) {
    // strip all whitespace
    warrantyID = warrantyID.replace(/\s/g, '');

    // it will be between 4 and 7 digits
    if (warrantyID.length < 4 || warrantyID.length > 7) {
        return {isOk: false, msg: "Warranty ID must be between 4 and 7 characters long."};
    } 

    return {isOk: true, msg: ""};
}