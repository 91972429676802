import { useSelector, useDispatch } from "react-redux";
import { fetchSession, selectHasAccountNum, selectAccountNumFromSession, selectIsSessionLoaded, getCsrftoken } from "../../redux/sessionSlice";
import { useEffect } from "react";
import LoadingPage from "../../pages/loadingPage";

function AppWrapper({ children }) {
    const sessionLoaded = useSelector(selectIsSessionLoaded)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCsrftoken())
        if(!sessionLoaded) {
            dispatch(fetchSession());
        }
    }, [dispatch, sessionLoaded]);

    if(!sessionLoaded) {
        return <LoadingPage />
    } else {
        return children
    }
}

export default AppWrapper;