import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import TextInput from "../components/form/TextInput"
import { useDispatch, useSelector } from "react-redux"
import { selectPhone, setPhone, updatePhone } from "../redux/formSlice"
import checkIsValidPhone from "../components/validators/checkIsValidPhone"
import useReloadAccount from "../redux/useReloadAccount"

function PhonePage() {
    const phone = useSelector(selectPhone);
    const dispatch = useDispatch();
    const phoneCheck = checkIsValidPhone(phone);
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    const handleChange = (e) => {
        let newChar = e.target.value[e.target.value.length - 1];
        if(newChar == '+') {
            alert("Please don't use a + sign or a country code.");
            return;
        }

        let phone = e.target.value.replace(/[^0-9-]/g,'');

        if(phone.length > 3 && phone.substring(3,4) !== "-") {
            phone = phone.substring(0,3) + "-" + phone.substring(3);
        }

        if(phone.length > 7 && phone.substring(7,8) !== "-") {
            phone = phone.substring(0,7) + "-" + phone.substring(7, phone.length);
        }

        dispatch(setPhone(phone));
    }

    return (
        <MainLayoutWrapper
            lastUrl="/email" 
            nextUrl="/settlement" 
            isLoading={loading}
            handleNext={() => dispatch(updatePhone(phone))}
            advanceDisabled={!phoneCheck.isOk}
        >
            <OneColumnLeft 
                title="And what's a good phone number?" 
                mobileTitle="And what's a good phone number?"
                description="Please type the best phone number to reach you at throughout the day."
                mobileDescription="Please type the best phone number to reach you at throughout the day."
                size="normal"
            >
                <TextInput value={phone} onChange={(e) => handleChange(e)} />
                {!phoneCheck.isOk && phone ? <p className="text-red-500 text-sm mt-2">{phoneCheck.msg}</p> : null}
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default PhonePage;