import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import MultiParagraphPage from "../components/layout/MultiParagraphPage";
import { useDispatch, useSelector } from "react-redux";
import { selectBuilder, selectRenewalAmount, selectRenewalDate, selectIsAccountLoading, selectIsWoodtreat } from "../redux/accountSlice";
import useReloadAccount from "../redux/useReloadAccount";

function InformationPageFour() {
    const dispatch = useDispatch();
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const renewalAmount = useSelector(selectRenewalAmount)
    const renewalDate = useSelector(selectRenewalDate)
    const builder = useSelector(selectBuilder)
    const isAccountLoading = useSelector(selectIsAccountLoading)
    const isWoodtreat = useSelector(selectIsWoodtreat)

    return (
        <MainLayoutWrapper 
            headline="Termite Program Registration" 
            hideBottom={true}
            isLoading={isAccountLoading}
        >
            <MultiParagraphPage
                headline="How your termite program works"
                subheadline="Treating for termites can be intrusive & expensive. Here's how this program keeps things easy!"
                buttonUrl="/questions"
                buttonText="Next"
            >
                <div className="mb-8">
                    <p><b className="font-bold">PART 1: Before You move In</b></p>
                    {isWoodtreat && <p>Your new home journey started with us, thanks to {builder == "Unknown" ? "your builder" : builder}. Before you moved in, we applied a preventative termite treatment to your home's woodwork. This was the first step in safeguarding your new home against termites.</p>}
                    {!isWoodtreat && <p>Your new home journey started with us, thanks to {builder == "Unknown" ? "your builder" : builder}. Before you moved in, we applied a preventative termite treatment to the soil around your home. This was the first step in safeguarding your new home against termites.</p>}
                </div>
                <div className="mb-8">
                    <p><b className="font-bold">PART 2: Your First Year in Your Home</b></p>
                    <p>Your builder generously covered the first year of your termite program. This means no inspections are needed, as we just completed the treatment. However, if you spot any termites within the year, just give us a call, and we'll swoop in to take care of them.</p>
                </div>
                <div className="mb-8">
                    <p><b className="font-bold">PART 3: Future Years & Beyond</b></p>
                    <p>Your current termite program will expire on {renewalDate}. Look out for a renewal notice in your mail - it's your decision if you want to keep this valuable protection. We highly recommend it! Your builder has already invested in protecting your new home, and now it's your turn to maintain that shield.</p>
                </div>
                <div className="mb-8">
                    <p>Keeping your termite program active involves an annual termite inspection. If we do find termites, your program covers the cost of a comprehensive treatment worth $3000+ to ensure your home remains termite-free. Your peace of mind is worth every cent!</p>
                </div>
            </MultiParagraphPage>
        </MainLayoutWrapper>
    )
}

export default InformationPageFour