import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import OneColumnCentered from "../components/layout/OneColumnCentered";
import confettiImg from '../assets/confetti.png'
import logo from '../assets/logo-with-bg.jpg'
import useReloadAccount from "../redux/useReloadAccount";
import { useSelector } from "react-redux";

function WelcomePage() {
    useReloadAccount('/', false, null);
    const isAccountLoading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper 
            headline="Termite Program Registration" 
            isLoading={isAccountLoading} 
            hideBottom={true}
        >
            <OneColumnCentered 
                title="Kick-off Your Termite Program Registration"
                mobileTitle="Termite Program Registration"
                description="Get started with your termite program - it's easy and all online! To speed things up, keep the letter, door-hanger, or packet we sent you within reach. Ready to dive in? Hit 'Start'."
                buttonUrl="/account-search"
                buttonText="Start"
                emoji={<img src={confettiImg} alt="confetti" className="md:w-10 md:h-10 w-6 h-6" />}
                image={<img src={logo} alt="logo" className="w-36 md:w-72" /> }
            >
            </OneColumnCentered>
        </MainLayoutWrapper>
    )
}

export default WelcomePage