import LoadingPage from "./loadingPage";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAccountByAccountNumber, selectIsAccountError, selectIsAccountFound } from "../redux/accountSlice";

function AccountLoadingPage() {
    const location = useLocation();
    // split the query string into an array of strings
    const queryStringArray = location.search.split("=");
    const accountNumber = queryStringArray[1];
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const isAccountFound = useSelector(selectIsAccountFound);
    const isAccountError = useSelector(selectIsAccountError);

    useEffect(() => {
        if(isAccountFound) {
            navigate("/warranty-id")
        } 
        if(isAccountError) {
            navigate("/account-search")
        }
    }, [isAccountFound, isAccountError]);

    useEffect(() => {
        dispatch(fetchAccountByAccountNumber(accountNumber));
    }, [dispatch, accountNumber]);

    return (
        <LoadingPage>Account Loading...</LoadingPage>
    )
}

export default AccountLoadingPage;