import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import WelcomePage from './pages/welcomePage';
import AccountSearchPage from './pages/accountSearchPage'
import AddressSearchPage from './pages/addressSearchPage';
import WarrantyIdPage from './pages/warrantyIdPage';
import AccountDetailPage from './pages/accountDetailPage';
import MoreInfoPage from './pages/moreInfoPage';
import MediaSelectionPage from './pages/mediaSelectionPage';
import VideoExplanationPage from './pages/videoExplainationPage';
import InformationPageOne from './pages/informationPageOne';
import InformationPageTwo from './pages/informationPageTwo';
import InformationPageThree from './pages/informationPageThree';
import InformationPageFour from './pages/informationPageFour';
import QuestionsPage from './pages/questionsPage';
import NamePage from './pages/namePage';
import EmailPage from './pages/emailPage';
import PhonePage from './pages/phonePage';
import SettlementPage from './pages/settlementPage';
import AutoRenewalPage from './pages/autoRenewalPage';
import OfferPage from './pages/offerPage';
import DelayPage from './pages/delayPage';
import CompleteNoPage from './pages/completeNoPage';
import CompleteMaybePage from './pages/completeMaybePage';
import CompleteYesPage from './pages/completeYesPage';
import AddressSearchResultsPage from './pages/addressSearchResults';
import ContactPage from './pages/contactPage';
import ContactSuccessPage from './pages/contactSuccessPage';
import AppWrapper from './components/misc/AppWrapper';
import AccountLoadingPage from './pages/accountLoadingPage';
import { useEffect } from 'react';

function App() {
  return (
    <div>
      <AppWrapper>
        <Router>
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/account-search" element={<AccountSearchPage />} />
            <Route path="/account-loading" element={<AccountLoadingPage />} />
            <Route path="/address-search" element={<AddressSearchPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/warranty-id" element={<WarrantyIdPage />} />
            <Route path="/address-search-results" element={<AddressSearchResultsPage />} />
            <Route path="/account-details" element={<AccountDetailPage />} />
            <Route path="/more-info" element={<MoreInfoPage />} />
            <Route path="/media" element={<MediaSelectionPage />} />
            <Route path="/video" element={<VideoExplanationPage />} />
            <Route path="/info-text-1" element={<InformationPageOne />} />
            <Route path="/info-text-2" element={<InformationPageTwo />} />
            <Route path="/info-text-3" element={<InformationPageThree />} />
            <Route path="/info-text-4" element={<InformationPageFour />} />
            <Route path="/questions" element={<QuestionsPage />} />
            <Route path="/name" element={<NamePage />} />
            <Route path="/email" element={<EmailPage />} />
            <Route path="/phone" element={<PhonePage />} />
            <Route path="/settlement" element={<SettlementPage />} />
            <Route path="/auto-renewal" element={<AutoRenewalPage />} />
            <Route path="/offer" element={<OfferPage />} />
            <Route path="/delay" element={<DelayPage />} />
            <Route path="/complete-no" element={<CompleteNoPage />} />
            <Route path="/complete-maybe" element={<CompleteMaybePage />} />
            <Route path="/complete-yes" element={<CompleteYesPage />} />
            <Route path="/contact-success" element={<ContactSuccessPage />} />
          </Routes>
        </Router>
      </AppWrapper>
    </div>
  );
}

export default App;
