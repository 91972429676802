import { Link } from "react-router-dom";

function NavLink({ location, children, className = ""}) {
    return (
        <Link to={location}>
            <div className={`w-full text-yellowSecondary underline cursor-pointer hover:opacity-70 font-sans ${className}`}>
                {children}
            </div>
        </Link>
    )
}

export default NavLink;