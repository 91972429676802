export default function checkIsValidDate(date) {
    // Check the basic format using a regular expression for YYYY-MM-DD
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (!dateRegex.test(date)) {
        return {isOk: false, msg: "Invalid date format. Please use YYYY-MM-DD."};
    }

    // Use the built-in Date object to further validate
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) {
        return {isOk: false, msg: "Invalid date."};
    }

    return {isOk: true, msg: ""};
}
