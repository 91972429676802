import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function Warning({ children, isMobile=false }) {
    const [open, setOpen] = useState(true);

    if (!open) {
        return null;
    }

    return (
        <div className="slide-down md:mx-16 my-20">
            <Alert 
                severity="warning" 
                className={"mb-4 sm:block md:fixed" + (isMobile ? " " : " warning-alert")}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {children}
            </Alert>
        </div>
    );
}

export default Warning;
