function StatusCallOut({ isActive=false, children, width="180px" }) {
    const color = isActive ? "bg-lightGreen text-white" : "bg-lightRed text-black"

    return (
        <div className={`flex justify-center rounded-md py-1 px-3 font-bold gap-x-3 items-center ${color}`} style={{width: width}}>
            <span className={`text-sm`}>{children}</span>
        </div>
    )
}

export default StatusCallOut;