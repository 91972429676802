import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import OneColumnLeft from "../components/layout/OneColumnLeft";
import { useEffect, useState } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchAddress, selectAddressSearchResults, selectIsAccountLoading } from "../redux/accountSlice";
import NavButton from "../components/layout/navButton";


function AddressSearchResultsPage() {
    const [pageNum, setPageNum] = useState(1)
    const options = useSelector(selectAddressSearchResults)
    const startIndex = (pageNum - 1) * 5
    const endIndex = startIndex + 5
    const navigate = useNavigate()
    const isAccountLoading = useSelector(selectIsAccountLoading)

    // the query params have a search param that is the address
    const location = useLocation()
    const search = location.search
    const params = new URLSearchParams(search)
    const address = params.get('search')
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(searchAddress(address))
    }, [address])
    
    const title = options.length === 0 ? "We couldn't find any results" : "We found a few results"
    const description = options.length === 0 ? "We couldn't find any results for your address. Please try again or click the button below to contact us" : "lease select your address from the list below. If you don't see it click, I don't see it below."
    const padding = options.length === 0 ? "pb-36 md:pb-24 mt-48 md:mt-0 justify-center" : "pt-48"
    
    return (
        <MainLayoutWrapper isLoading={isAccountLoading}>
            <OneColumnLeft
                title={title}
                mobileTitle={title}
                description={description}
                mobileDescription={description}
                padding={padding}
            >
                {options.length === 0 && (
                    <div className="flex flex-col justify-center">
                        <div className="flex mt-9 gap-x-2 items-start justify-start">
                            <NavButton isSolid={false} url="/address-search">Try Again</NavButton>
                            <NavButton url="/contact">Contact Us</NavButton>
                        </div>

                    </div>
                )}
                {options.length > 0 && (<div>
                    {options.slice(startIndex, endIndex).map((option, index) => {
                        return (
                            <div className="flex justify-between items-center border-b border-t border-white py-2">
                                <p className="text-white">{option.Address} {option.City}, {option.State} {option.Zip}</p>
                                <button
                                    className="bg-yellowSecondary text-darkGreen px-4 py-2 rounded-md"
                                    onClick={() => { navigate('/account-loading?account_num=' + option.Location) }}
                                >
                                    Select
                                </button>
                            </div>
                        )
                    }
                    )}
                </div>)}
                {options.length > 5 && (
                    <div className="w-full flex items-center justify-end">
                        {pageNum > 1 && <button className=" text-white px-4 py-2 rounded-lg" onClick={() => setPageNum(pageNum - 1)}><ArrowBackIosIcon /></button>}
                        <p className="text-white">{pageNum} of {Math.ceil(options.length / 5)}</p>
                        {pageNum < Math.ceil(options.length / 5) && <button className=" text-white px-4 py-2 rounded-lg" onClick={() => setPageNum(pageNum + 1)}><ArrowForwardIosIcon /></button>}
                    </div>
                )}

            </OneColumnLeft>

        </MainLayoutWrapper>
    )
}

export default AddressSearchResultsPage