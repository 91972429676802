import Warning from "../misc/Warning"
import CallOut from "../sales/CallOut"
import StatusCallOut from "../sales/StatusCallOut"


function OneColumnLeft({ title = "", description = "", children = "", emoji = "", callout="", padding="pb-36 md:pb-24 mt-48 md:mt-0 justify-center", fullHeight="h-full", showStatus=false, mobileTitle="", mobileDescription="", error="" }) {
    return (
        // the outer div is the container for the entire component
        <div className={`w-full ${fullHeight}`}>
            {/* This div controls the centering of the text elements on the screen */}
            <div className={`flex flex-col bg-primary items-center h-full text-white font-sans ${padding} overflow-y-scroll`}>
                <div className="w-full px-4 lg:w-2/3 md:px-8 lg:px-0">
                    {error && <Warning message={error} />}
                    {callout && <CallOut className="mb-4" width="275px">{callout}</CallOut>}
                    <h2 className="hidden md:flex text-lg md:text-xl lg:text-2xl font-bold font-sans mb-2 gap-x-4">{title} {emoji} {showStatus && <StatusCallOut isActive={false}>Unregistered</StatusCallOut>}</h2>
                    <h2 className="md:hidden flex text-lg md:text-xl lg:text-2xl font-bold font-sans mb-2 gap-x-4">{mobileTitle} {emoji}</h2>
                    <p className="hidden md:flex font-sans font-light mb-8" style={{ fontSize: "15px" }}>{description}</p>
                    <p className="md:hidden flex font-sans font-light mb-8" style={{ fontSize: "15px" }}>{mobileDescription}</p>
                    <div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OneColumnLeft