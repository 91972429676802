import CircularProgress from '@mui/material/CircularProgress';

function LoadingPage({children}) {
  return (
    <div className="App bg-primary w-full h-screen">
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="flex flex-col items-center justify-center">
                <CircularProgress color="inherit" style={{ 'width': '100px', 'height': '100', color: '#c2b51e', marginBottom: '20px' }} />
                <h1 className="text-white text-xl">{children}</h1>
            </div>
        </div>
    </div>
  );
}

export default LoadingPage;