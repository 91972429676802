import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import OneColumnCentered from "../components/layout/OneColumnCentered";
import ConfettiExplosion from 'react-confetti-explosion';


function CompleteYesPage() {

    return (
        <MainLayoutWrapper isLoading={false}>
            <div className="flex justify-center">
                <ConfettiExplosion width="1500" duration={2500} particleCount={500} />
            </div>

            <OneColumnCentered
                title="Success! We'll Reach Out Soon to Get You Started"
                mobileTitle="Awesome! We'll Reach Out Soon!"
                description="Awesome! Your termite program is now registered. Our team will be in touch shortly to schedule the first service for your SeasonGuard free trial and set up your billing. Looking forward to safeguarding your home!"
                buttonUrl={"https://bettertermite.com"}
                buttonText={"Go To Main Site"}
            >
            </OneColumnCentered>
        </MainLayoutWrapper>
    )
}

export default CompleteYesPage