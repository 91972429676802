import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import config from "../config";

export const fetchAccountByAccountNumber = createAsyncThunk(
    "account/fetchAccountByAccountNumber",
    async (accountNumber) => {
        const response = await fetch(
            `${config.server}/api/location-search-account/?location=${accountNumber}`,
            { 'credentials': 'include'}
        );
        
        if (!response.ok || response.status === 404) {
            throw new Error("Something went wrong");
        } else {
            const data = await response.json();
            return data;
        }
    }
);

export const reloadSession = createAsyncThunk(
    "account/reloadSession",
    async (_, { rejectWithValue }) => {
        const response = await fetch(
            `${config.server}/api/reload-session/`,
            { 'credentials': 'include'}
        );
        
        if (!response.ok) {
            return rejectWithValue("Something went wrong");
        } else {
            const data = await response.json();
            return data;
        }
    }
);

export const searchAddress = createAsyncThunk(
    "account/searchAddress",
    async (address) => {
        const response = await fetch(
            `${config.server}/api/location-search-address/?address=${address}`,
            { 'credentials': 'include'}
        );
        
        if (!response.ok) {
            throw new Error("Something went wrong");
        } else {
            const data = await response.json();
            return data;
        }
    }
);

const accountSlice = createSlice({
    name: "account",
    initialState: {
        accountNumber: "",
        address: "",
        airtableData: {},
        isAccountLoading: false,
        isAccountError: false,
        accountErrorMessage: "",
        addressSearchResults: [],
    },
    reducers: {
        setAccountNumber: (state, action) => {
            state.accountNumber = action.payload;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        setAccountError: (state, action) => {
            state.isAccountError = true;
            state.accountErrorMessage = action.payload;
        }
    },
    extraReducers: {
        [fetchAccountByAccountNumber.pending]: (state, action) => {
            state.isAccountLoading = true;
            state.isAccountError = false;
            state.accountErrorMessage = "";
        },
        [fetchAccountByAccountNumber.fulfilled]: (state, action) => {
            state.isAccountLoading = false;
            state.airtableData = action.payload.data;
        },
        [fetchAccountByAccountNumber.rejected]: (state, action) => {
            state.isAccountLoading = false;
            state.isAccountError = true;
            state.accountErrorMessage = "Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.";
        },
        [reloadSession.pending]: (state, action) => {
            state.isAccountLoading = true;
            state.isAccountError = false;
            state.accountErrorMessage = "";
        },
        [reloadSession.fulfilled]: (state, action) => {
            state.isAccountLoading = false;
            state.airtableData = action.payload.data;
        },
        [reloadSession.rejected]: (state, action) => {
            state.isAccountLoading = false;
            state.isAccountError = true;
        },
        [searchAddress.pending]: (state, action) => {
            state.isAccountLoading = true;
            state.isAccountError = false;
            state.accountErrorMessage = "";
        },
        [searchAddress.fulfilled]: (state, action) => {
            state.isAccountLoading = false;
            state.addressSearchResults = action.payload.data;
        },
        [searchAddress.rejected]: (state, action) => {
            state.isAccountLoading = false;
            state.isAccountError = true;
            state.accountErrorMessage = "Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.";
        },
    },
});

export const { setAccountNumber, setAddress, setAccountError } = accountSlice.actions;
export const selectAccountNumber = (state) => state.account.accountNumber;
export const selectAddressSearch = (state) => state.account.address;
export const selectAddressSearchResults = (state) => state.account.addressSearchResults;
export const selectIsAccountError = (state) => state.account.isAccountError;
export const selectAccountErrorMessage = (state) => state.account.accountErrorMessage;
export const selectIsAccountLoading = (state) => state.account.isAccountLoading;
export const selectIsAccountFound = (state) => Object.keys(state.account.airtableData).length > 0;
export const selectAddress = (state) => state.account.airtableData['Address'];
export const selectCity = (state) => state.account.airtableData['City'];
export const selectState = (state) => state.account.airtableData['State'];
export const selectZip = (state) => state.account.airtableData['Zip'];
export const selectBuilder = (state) => state.account.airtableData['Source'];
export const selectPretreatTech = (state) => state.account.airtableData['Pretreat Tech'];
export const selectPretreatDate = (state) => state.account.airtableData['Pretreat Date'];
export const selectActiveService = (state) => state.account.airtableData['Active Services'];
export const selectIsWoodtreat = (state) => state.account.airtableData['Active Services']?.includes('WTR');
export const selectSubdivision = (state) => state.account.airtableData['Subdivision Name'];
export const selectRenewalAmount = (state) => state.account.airtableData['Total'];
export const selectStartDate = (state) => state.account.airtableData['Start Date'];
export const selectRenewalDate = (state) => state.account.airtableData['Renewal Date'];
export const selectIsRegistered = (state) => state.account.airtableData['Is Active'] == 'Yes';
export const selectIsReloadFailed = (state) => state.account.isAccountError;
export const selectHeadline = (state) => Object.keys(state.account.airtableData).length > 0 ? state.account.airtableData['Upsell Offer']['headline'] : '';
export const selectOfferDescription = (state) => Object.keys(state.account.airtableData).length > 0 ? state.account.airtableData['Upsell Offer']['description'] : '';
export const selectSubPrice = (state) => Object.keys(state.account.airtableData).length > 0 ? state.account.airtableData['Sub Price'] : '';
export default accountSlice.reducer;
