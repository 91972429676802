export default function checkIsValidPhone(phone) {
    // Check the basic format using a regular expression
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

    if (!phoneRegex.test(phone)) {
        return {isOk: false, msg: "Invalid phone format. Please use xxx-xxx-xxxx (for example, 703-683-2000)."};
    }

    if(phone === "703-683-2000") {
        return {isOk: false, msg: "Please use your real number... that ours :)."};
    }

    // check if a character other than #4 or #8 is a hyphen
    for (let i = 0; i < phone.length; i++) {
        if (i !== 3 && i !== 7 && phone[i] === "-") {
            return {isOk: false, msg: "Invalid phone format. Please use xxx-xxx-xxxx (for example, 703-683-2000)."};
        }
    }

    const invalidNumbers = ["000-000-0000", "111-111-1111", "222-222-2222", "333-333-3333", "444-444-4444", "555-555-5555", "666-666-6666", "777-777-7777", "888-888-8888", "999-999-9999", "123-456-7890", "987-654-3210", "123-123-1234"];
    if (invalidNumbers.includes(phone)) {
        return {isOk: false, msg: "Please use a real number, not a placeholder."};
    }

    // Check length (12 characters considering hyphens)
    if (phone.length !== 12) {
        return {isOk: false, msg: "Phone number length is incorrect."};
    }

    // Split phone number by hyphen to check individual segments
    const segments = phone.split("-");

    if(segments[0][0] == '0') {
        return {isOk: false, msg: "Phone number should be numeric and not start with zero."};
    }

    // Ensure all segments are numeric and don't start with zero
    for (const segment of segments) {
        if (!/^\d+$/.test(segment)) {
            return {isOk: false, msg: "Phone number segments should be numeric and not start with zero."};
        }
    }

    return {isOk: true, msg: ""};
}
