import CheckWhite from "../assets/checkWhite";
import XWhite from "../assets/xwhite";
import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import OneColumnLeft from "../components/layout/OneColumnLeft";
import { useSelector } from "react-redux";
import { selectAddress, selectCity, selectState, selectZip, selectSubdivision, selectBuilder, selectPretreatDate, selectPretreatTech, selectActiveService, selectRenewalAmount, selectStartDate, selectRenewalDate, selectIsRegistered, selectIsAccountLoading } from "../redux/accountSlice";
import useReloadAccount from "../redux/useReloadAccount";
import { Alert } from "@mui/material";

function AccountDetailPage() {
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');

    // selectors for the page
    const address = useSelector(selectAddress);
    const city = useSelector(selectCity);
    const state = useSelector(selectState);
    const zip = useSelector(selectZip);
    const subdivision = useSelector(selectSubdivision)
    const builder = useSelector(selectBuilder)
    const pretreatDate = useSelector(selectPretreatDate)
    const pretreatTech = useSelector(selectPretreatTech)
    const activeService = useSelector(selectActiveService)
    const renewalAmount = useSelector(selectRenewalAmount)
    const startDate = useSelector(selectStartDate)
    const renewalDate = useSelector(selectRenewalDate)
    const isRegistered = useSelector(selectIsRegistered)
    const isAccountLoading = useSelector(selectIsAccountLoading)

    const nextUrl = isRegistered ? '' : "/more-info"
    const lastUrl = isRegistered ? '' : "/account-search"

    return (
        <MainLayoutWrapper
            headline="Termite Program Registration"
            lastUrl={lastUrl}
            nextUrl={nextUrl}
            isLoading={isAccountLoading}
        >
            <div className="p-4 md:p-0">
                <OneColumnLeft
                    description=""
                    padding="pt-12 md:pt-24"
                    showStatus={false}
                >
                    {isRegistered && <Alert sx={{ marginBottom: '30px' }}>
                        Want to learn more about other plans we offer? Visit our <a target="_blank" className="underline cursor-pointer text-yellowSecondary font-semibold" href="https://bettertermite.ac-page.com/season-guard-new-construction">New Construction Pest Plans Page</a> or give us a call anytime at 703-683-2000.
                    </Alert>}
                    {isRegistered ?
                        <span className="inline-block md:hidden bg-lightGreen rounded-lg px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">Activated</span> :
                        <span className="inline-block md:hidden bg-lightRed rounded-lg px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">Not Activated</span>
                    }
                    <div className="flex gap-x-4">
                        <h2 className="mb-2 text-2xl font-semibold text-white">Account Details</h2>
                        <div className="mb-4">
                            {isRegistered ?
                                <span className="hidden md:inline-block bg-lightGreen rounded-lg px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">Activated</span> :
                                <span className="hidden md:inline-block bg-lightRed rounded-lg px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">Not Activated</span>
                            }
                        </div>
                    </div>
                    <p className="mb-8 text-white text-sm md:text-md">{isRegistered ? "This account is already registered. No further action is required at this point. Please contact us at 703-683-2000 with any questions, updates to your account or if you believe your account was activated by mistake." : "Good news! We found your account Please review these account details to make sure they look right before proceding."}</p>
                    {/* make paragraphs have more space below */}
                    <div className="mb-10 text-sm md:text-md text-white leading-6">
                        <p><b>Address</b>: {address}, {city}, {state} {zip}</p>
                        {(builder != "Unknown" && builder) && <p><b>Builder</b>: {builder}</p>}
                        <p><b>Renewal Cost</b>: First year paid for by builder, then can be renewed for ${renewalAmount}/year</p>
                        {pretreatDate && <p><b>Termite Treatment Date</b>: {pretreatDate} by {pretreatTech ? pretreatTech : "Better Termite & Pest Control"}</p>}
                        <p><b>Type of Treatment</b>: {activeService == 'WTR' ? 'Termite Wood Treat' : 'Soil Termite Pretreatment'}</p>
                        <p><b>Renewal Period</b>: {startDate}-{renewalDate}</p>
                        {(subdivision && subdivision != "None") && (<p><b>Neighborhood</b>: {subdivision}</p>)}
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 mb-4">
                            <p className="font-bold text-lg mb-4">Covered By Your Builder's Plan</p>
                            <p className="flex gap-x-2 mb-4 text-sm md:text-md"><CheckWhite /> Termites</p>
                            <p className="flex gap-x-2 mb-4 text-sm md:text-md"><CheckWhite /> Retreatment for Termites if Termites are Found</p>
                        </div>
                        <div className="w-full md:w-1/2 mb-8 md:mb-0">
                            <p className="font-bold text-lg mb-4">Not Covered By Your Current Plan</p>
                            <p className="flex gap-x-2 mb-4 text-sm md:text-md"><XWhite /> Ants</p>
                            <p className="flex gap-x-2 mb-4 text-sm md:text-md"><XWhite /> Millipedes</p>
                            <p className="flex gap-x-2 mb-4 text-sm md:text-md"><XWhite /> Rodents</p>
                            <p className="flex gap-x-2 mb-4 text-sm md:text-md"><XWhite /> Spiders</p>
                            <p className="flex gap-x-2 mb-4 text-sm md:text-md"><XWhite /> Mosquitoes</p>
                        </div>
                    </div>
                    <div className="bg-primary pb-0 md:pb-36 text-sm md:text-md">
                        <h2 className="mb-4 text-lg font-bold">Terms of Your Warranty</h2>
                        <p className="mb-4">Better Termite & Pest Control Co., Inc. (BTPC) certifies that if, during the life of this warranty, an inspection of the areas treated shows infestation by Subterranean Termites, BTPC will furnish, at no cost to the holder of this warranty, all labor, chemicals, materials, and equipment necessary to eliminate such infestation. Should it become necessary during retreatment to remove structural components of the structure, appurtenance, carpeting, floor covering, siding, sod, soil, shrubbery or similar items, such removal and replacement are the sole responsibility of the property owner and shall be done at the property owners’ sole cost and expense.   Installation of termite bait monitoring systems are not included in this warranty without prior written agreement.</p>
                        <p className="mb-4">In the event that drilling or hole boring into any surface becomes necessary to treat the infestation by Subterranean Termites, BTPC shall not be responsible for any damage incurred as a result of such drilling or hole boring.</p>
                        <p className="mb-4">This warranty is limited solely to the elimination of Subterranean Termite infestation at the expense of BTPC, and in no way obligates BTPC to repair or replace any physical or structural damage whatsoever, caused by Subterranean Termite infestation. BTPC assumes no responsibility for payment or charges to any other company employed by the builder, owner, or his agents for inspection, consultation, or any other service or work rendered.</p>
                        <p className="mb-4">The provisions of this warranty may be renewable annually upon expiration at the option of BTPC and upon agreement of the property owner to pay the renewal fee. It is understood that BTPC reserves the right to adjust the renewal fee at any time. Upon any anniversary date, BTPC reserves the right to cancel the warranty.   Upon the renewal of the warranty, BTPC will inspect the accessible areas inside and outside of the foundation structure.</p>
                        <p className="mb-4">If during the life of the warranty, additions or alterations are made to the structure, which could create a termite hazard or interfere with the chemical protective barrier , or if a termite hazard is created or the chemical protective barrier is interfered with by an Act of God, or by acts such as firev1ood stored close to the exterior of the structure, construction of wooden fences or sheds, or storage of any wood material , or any objects containing cellulose, within twenty -five feet of the structure without prior written consent of an officer of BTPC , this warranty shall become null and void as of the time of such act. If the property owners or his agents are notified of any of the above hazards and the hazardous condition is not corrected within ten days of such notification, this warranty shall become null and void.</p>
                        <p className="mb-4">This Subterranean Termite Limited Warranty is effective only if validly issued by an officer of BTPC. Non-payment of services rendered invalidates this warranty in its entirety. Registering this program does not alter or extend the duration or renewal period of this warranty.</p>
                    </div>
                </OneColumnLeft>
            </div>
        </MainLayoutWrapper>
    )
}

export default AccountDetailPage