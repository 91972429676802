import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import SingleSelectContainer from "../components/form/SingleSelectContainer"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useReloadAccount from "../redux/useReloadAccount"

function MoreInfoPage() {
    const [decision, setDecision] = useState(null)
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper 
            headline="Termite Program Registration" 
            lastUrl={'/account-details'} 
            nextUrl={decision === 'yes'  ? "/media" : "/name"} 
            isLoading={loading}
            advanceDisabled={decision == null}
        >
            <OneColumnLeft 
                title="Would you like a quick run down of your termite program?" 
                mobileTitle="Would you like a quick run down of your termite program?"
                description="Let us know if you are in a rush!"
                mobileDescription="Let us know if you are in a rush!"
            >
                <SingleSelectContainer 
                    options={[
                        { "value": "yes", "text": "Yes please!", handleClick: () => setDecision("yes") },
                        { "value": "no", "text": "No thanks, I'm in a rush", handleClick: () => setDecision("no") },
                    ]}
                />
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default MoreInfoPage