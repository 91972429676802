import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import config from "../config";

export const updateWarrantyID = createAsyncThunk(
    'form/updateWarrantyID',
    async (warrantyID) => {
        let csrfToken;
        try {
            csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
        } catch {
            console.error('CSRF token not found. Refreshing page.');
            window.location.reload();
        }
        const response = await fetch(
            `${config.server}/api/contact/warranty-id/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({
                    warranty_id: warrantyID,
                }),
                credentials: 'include',
            }
        );
    }
)

export const updateName = createAsyncThunk(
    'form/updateName',
    async (name) => {
        let csrfToken;
        try {
            csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
        } catch {
            console.error('CSRF token not found. Refreshing page.');
            window.location.reload();
        }
        const response = await fetch(
            `${config.server}/api/contact/name/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({
                    name: name,
                }),
                credentials: 'include',
            }
        );
    }
)

export const updateEmail = createAsyncThunk(
    'form/updateEmail',
    async (email) => {
        let csrfToken;
        try {
            csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
        } catch {
            console.error('CSRF token not found. Refreshing page.');
            window.location.reload();
        }

        const response = await fetch(
            `${config.server}/api/contact/email/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({
                    email: email,
                }),
                credentials: 'include',
            }
        );
    }
)

export const updatePhone = createAsyncThunk(
    'form/updatePhone',
    async (phone) => {
        let csrfToken;
        try {
            csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
        } catch {
            console.error('CSRF token not found. Refreshing page.');
            window.location.reload();
        }
        const response = await fetch(
            `${config.server}/api/contact/phone/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({
                    phone: phone,
                }),
                credentials: 'include',
            }
        );
    }
)

export const updateSettlementDate = createAsyncThunk(
    'form/updateSettlementDate',
    async (settlementDate) => {
        let csrfToken;
        try {
            csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
        } catch {
            console.error('CSRF token not found. Refreshing page.');
            window.location.reload();
        }
        const response = await fetch(
            `${config.server}/api/contact/settlement-date/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({
                    settlement_date: settlementDate,
                }),
                credentials: 'include',
            }
        );
    }
)

export const updateSetupAutoRenew = createAsyncThunk(
    'form/updateSetupAutoRenew',
    async (setupAutoRenew) => {
        let csrfToken;
        try {
            csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
        } catch {
            console.error('CSRF token not found. Refreshing page.');
            window.location.reload();
        }
        const response = await fetch(
            `${config.server}/api/contact/auto-renew/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({
                    auto_renew: setupAutoRenew,
                }),
                credentials: 'include',
            }
        );
    }
)

export const updateUpgradeNow = createAsyncThunk(
    'form/updateUpgradeNow',
    async (upgradeNow) => {
        let csrfToken;
        try {
            csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
        } catch {
            console.error('CSRF token not found. Refreshing page.');
            window.location.reload();
        }
        const response = await fetch(
            `${config.server}/api/contact/upgrade-decision/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({
                    upgrade_decision: upgradeNow,
                }),
                credentials: 'include',
            }
        );
    }
)

export const updateUpgradeLater = createAsyncThunk(
    'form/updateUpgradeLater',
    async (upgradeLater) => {
        let csrfToken;
        try {
            csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
        } catch {
            console.error('CSRF token not found. Refreshing page.');
            window.location.reload();
        }
        const response = await fetch(
            `${config.server}/api/contact/upgrade-delay-decision/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({
                    upgrade_delay_decision: upgradeLater,
                }),
                credentials: 'include',
            }
        );
    }
)

export const updateQuestions = createAsyncThunk(
    'form/updateQuestions',
    async (questions) => {
        let csrfToken;
        try {
            csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
        } catch {
            console.error('CSRF token not found. Refreshing page.');
            window.location.reload();
        }
        const response = await fetch(
            `${config.server}/api/contact/questions/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({
                    questions: questions,
                }),
                credentials: 'include',
            }
        );
    }
)

const formSlice = createSlice({
    name: "form",
    initialState: {
        warrantyId: "",
        name: "",
        email: "",
        phone: "",
        settlementDate: "",
        setupAutoRenew: null,
        upgradeNow: null,
        upgradeLater: null,
        questions: ''
    },
    reducers: {
        setWarrantyId: (state, action) => {
            state.warrantyId = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
        },
        setSettlementDate: (state, action) => {
            state.settlementDate = action.payload;
        },
        setSetupAutoRenew: (state, action) => {
            state.setupAutoRenew = action.payload;
        },
        setUpgradeNow: (state, action) => {
            state.upgradeNow = action.payload;
        },
        setUpgradeLater: (state, action) => {
            state.upgradeLater = action.payload;
        },
        setQuestions: (state, action) => {
            state.questions = action.payload;
        }
    }, 
    extraReducers: {
        [updateWarrantyID.pending]: (state, action) => {
            state.isWarrantyIDLoading = true;
            state.isWarrantyIDError = false;
            state.warrantyIDErrorMessage = "";
        },
        [updateWarrantyID.fulfilled]: (state, action) => {
            state.isWarrantyIDLoading = false;
        },
        [updateWarrantyID.rejected]: (state, action) => {
            state.isWarrantyIDLoading = false;
            state.isWarrantyIDError = true;
            state.warrantyIDErrorMessage = "Warranty ID not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.";
        },
        [updateName.pending]: (state, action) => {
            state.isNameLoading = true;
            state.isNameError = false;
            state.nameErrorMessage = "";
        },
        [updateName.fulfilled]: (state, action) => {
            state.isNameLoading = false;
        },
        [updateName.rejected]: (state, action) => {
            state.isNameLoading = false;
            state.isNameError = true;
            state.nameErrorMessage = "Name not found. Please try again.";
        },
        [updateEmail.pending]: (state, action) => {
            state.isEmailLoading = true;
            state.isEmailError = false;
            state.emailErrorMessage = "";
        },
        [updateEmail.fulfilled]: (state, action) => {
            state.isEmailLoading = false;
        },
        [updateEmail.rejected]: (state, action) => {
            state.isEmailLoading = false;
            state.isEmailError = true;
            state.emailErrorMessage = "Email not found. Please try again.";
        },
        [updatePhone.pending]: (state, action) => {
            state.isPhoneLoading = true;
            state.isPhoneError = false;
            state.phoneErrorMessage = "";
        },
        [updatePhone.fulfilled]: (state, action) => {
            state.isPhoneLoading = false;
        },
        [updatePhone.rejected]: (state, action) => {
            state.isPhoneLoading = false;
            state.isPhoneError = true;
            state.phoneErrorMessage = "Phone not found. Please try again.";
        },
        [updateSettlementDate.pending]: (state, action) => {
            state.isSettlementDateLoading = true;
            state.isSettlementDateError = false;
            state.settlementDateErrorMessage = "";
        },
        [updateSettlementDate.fulfilled]: (state, action) => {
            state.isSettlementDateLoading = false;
        },
        [updateSettlementDate.rejected]: (state, action) => {
            state.isSettlementDateLoading = false;
            state.isSettlementDateError = true;
            state.settlementDateErrorMessage = "Settlement date not found. Please try again.";
        },
        [updateSetupAutoRenew.pending]: (state, action) => {
            state.isSetupAutoRenewLoading = true;
            state.isSetupAutoRenewError = false;
            state.setupAutoRenewErrorMessage = "";
        },
        [updateSetupAutoRenew.fulfilled]: (state, action) => {
            state.isSetupAutoRenewLoading = false;
        },
        [updateSetupAutoRenew.rejected]: (state, action) => {
            state.isSetupAutoRenewLoading = false;
            state.isSetupAutoRenewError = true;
            state.setupAutoRenewErrorMessage = "Auto renew not found. Please try again.";
        },
        [updateUpgradeNow.pending]: (state, action) => {
            state.isUpgradeNowLoading = true;
            state.isUpgradeNowError = false;
            state.upgradeNowErrorMessage = "";
        },
        [updateUpgradeNow.fulfilled]: (state, action) => {
            state.isUpgradeNowLoading = false;
        },
        [updateUpgradeNow.rejected]: (state, action) => {
            state.isUpgradeNowLoading = false;
            state.isUpgradeNowError = true;
            state.upgradeNowErrorMessage = "Upgrade now not found. Please try again.";
        },
        [updateUpgradeLater.pending]: (state, action) => {
            state.isUpgradeLaterLoading = true;
            state.isUpgradeLaterError = false;
            state.upgradeLaterErrorMessage = "";
        },
        [updateUpgradeLater.fulfilled]: (state, action) => {
            state.isUpgradeLaterLoading = false;
        },
        [updateUpgradeLater.rejected]: (state, action) => {
            state.isUpgradeLaterLoading = false;
            state.isUpgradeLaterError = true;
            state.upgradeLaterErrorMessage = "Upgrade later not found. Please try again.";
        },
        [updateQuestions.pending]: (state, action) => {
            state.isQuestionsLoading = true;
            state.isQuestionsError = false;
            state.questionsErrorMessage = "";
        },
        [updateQuestions.fulfilled]: (state, action) => {
            state.isQuestionsLoading = false;
        },
        [updateQuestions.rejected]: (state, action) => {
            state.isQuestionsLoading = false;
            state.isQuestionsError = true;
            state.questionsErrorMessage = "Questions not found. Please try again.";
        },
    },
});


export const { setWarrantyId, setName, setEmail, setPhone, setSettlementDate, setSetupAutoRenew, setUpgradeNow, setUpgradeLater, setQuestions } = formSlice.actions;
export const selectWarrantyId = (state) => state.form.warrantyId;
export const selectName = (state) => state.form.name;
export const selectEmail = (state) => state.form.email;
export const selectPhone = (state) => state.form.phone;
export const selectSettlementDate = (state) => state.form.settlementDate;
export const selectSetupAutoRenew = (state) => state.form.setupAutoRenew;
export const selectUpgradeNow = (state) => state.form.upgradeNow;
export const selectUpgradeLater = (state) => state.form.upgradeLater;
export const selectQuestions = (state) => state.form.questions;
export default formSlice.reducer;