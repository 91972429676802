import NavButton from "./navButton"

function MultiParagraphPage({ headline, subheadline, children, buttonUrl, buttonText }) {
    return (
        <div className="w-full h-full">
            <div className="flex justify-center items-center h-full md:pb-24 mt-36 lg:mt-48 text-white font-sans">
                <div className="lg:w-1/2 w-full px-4">
                    <h2 className="text-lg md:text-2xl font-bold font-sans mb-2 text-center">{headline}</h2>
                    <h3 className="text-md lg:text-lg font-sans mb-10 text-center">{subheadline}</h3>
                    <div className="font-sans font-light mb-8">
                        {children}
                    </div>
                    <div className="flex justify-center">
                        <NavButton url={buttonUrl}>{buttonText}</NavButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultiParagraphPage