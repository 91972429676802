import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import OneColumnCentered from "../components/layout/OneColumnCentered";

function ContactSuccessPage() {
    return (
        <MainLayoutWrapper isLoading={false}>
            <OneColumnCentered 
                title="Almost There! Our Team Will Be in Touch"
                mobileTitle="Almost There! We'll Talk Soon"
                description="Fantastic! Our team will now work on locating your home and will get in touch to help you complete the registration process. See you soon!"
                buttonUrl={"https://bettertermite.com"}
                buttonText={"Go To Main Site"}
            >
            </OneColumnCentered>
        </MainLayoutWrapper>
    )
}

export default ContactSuccessPage