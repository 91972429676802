import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import TextInput from "../components/form/TextInput"
import { useDispatch, useSelector } from "react-redux"
import { selectName, setName, updateName } from "../redux/formSlice"
import checkIsValidName from "../components/validators/checkIsValidName"
import useReloadAccount from "../redux/useReloadAccount"

function NamePage() {
    const name = useSelector(selectName);
    const dispatch = useDispatch();
    const nameCheck = checkIsValidName(name);
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper 
            lastUrl="/more-info" 
            nextUrl="/email" 
            isLoading={loading}
            handleNext={() => dispatch(updateName(name))}
            advanceDisabled={!nameCheck.isOk}
        >
            <OneColumnLeft 
                title="First, what's your name?" 
                mobileTitle="First, What's your name?"
                description="To register your program, we'll first need to attach your contact information to your account. Please include your first & last name."
                mobileDescription="To register your program, we'll first need to attach your contact information to your account."
            >
                <TextInput value={name} onChange={(e) => dispatch(setName(e.target.value))} />
                {!nameCheck.isOk && name ? <p className="text-red-500 text-sm mt-2">{nameCheck.msg} </p> : null}
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default NamePage;