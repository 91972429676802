import NavButton from "./navButton"
import { useMediaQuery } from 'react-responsive'

function TwoColumn({ title = "", description = "", children = "", orientation = "left", imageUrl = "", buttonUrl = "", buttonText = "" }) {
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const supportingPanel = (
        <div className="w-full sm:w-1/2 justify-center item-center flex mb-4 sm:mb-0">
            <img src={imageUrl} alt="logo" className="w-96" />
        </div>
    )

    return (
        <div className="w-full h-full">
            <div className="flex flex-col sm:flex-row justify-center items-center h-full sm:pb-12 pt-12 mt-12 text-white font-sans lg:px-24 px-4">
                { (orientation === "right" || isMobile)  && supportingPanel}
                <div className="w-full sm:w-1/2 px-0 md:px-4 lg:px-0">
                    <h2 className="text-lg md:text-xl lg:text-2xl font-bold font-sans mb-2 flex gap-x-4">{title}</h2>
                    <div className="font-sans font-light mb-8">
                        {children}
                    </div>
                    <NavButton url={buttonUrl}>{buttonText}</NavButton>
                </div>
                { (orientation === "left" && !isMobile) && supportingPanel}
            </div>
        </div>
    )
}

export default TwoColumn