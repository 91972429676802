import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import TwoColumn from "../components/layout/TwoColumn";
import termite from '../assets/termite.png'
import soilTreatExplainer from '../assets/soil_treat_explainer.png'
import { selectIsWoodtreat } from "../redux/accountSlice";
import { useSelector } from "react-redux";
import useReloadAccount from "../redux/useReloadAccount";

function InformationPageThree() {
    const isWoodtreat = useSelector(selectIsWoodtreat)
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper headline="Termite Program Registration" hideBottom={true} isLoading={loading}>
            <TwoColumn
                imageUrl={isWoodtreat ? termite : soilTreatExplainer}
                title="How we prevent termites"
                buttonText="Next"
                buttonUrl="/info-text-4"
                orientation="left"
            >
                <p className="mb-4">In our area, termites live in the ground and eat roots and dead trees. Things go wrong when land forest is cleared out to build your home. Termites can mistake your home for a root or tree and begin tunneling towards your home.</p>
                {isWoodtreat && <p>The green "borate" treatment shown a few slides ago prevents the termites from tunneling up through your house. Since it doesn't kill nearby colonies though, it is very important to have an annual inspection, starting around 1 to 2 years after the initial treatment. If we find termite activity in the soil nearby, we have to closely watch your house to see if we need to do an additional treatment targeted towards the nearby colonies.</p>}
                {!isWoodtreat && <p>The treatment we performed creates a barrier around your home that helps prevent termites from crossing into your home. Since it doesn't kill nearby colonies though, it is very important to have an annual inspection, starting around 1 to 2 years after the initial treatment. If we find termite activity in the soil nearby, we have to closely watch your house to see if we need to do an additional treatment targeted towards the nearby colonies.</p>}
            </TwoColumn>
        </MainLayoutWrapper>
    )
}

export default InformationPageThree