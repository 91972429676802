import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import TextInput from "../components/form/TextInput"
import { useDispatch, useSelector } from "react-redux"
import { selectSettlementDate, setSettlementDate, updateSettlementDate } from "../redux/formSlice"
import checkIsValidDate from "../components/validators/checkIsValidDates"
import useReloadAccount from "../redux/useReloadAccount"

function SettlementPage() {
    const settlementDate = useSelector(selectSettlementDate);
    const dispatch = useDispatch();
    const settlementDateCheck = checkIsValidDate(settlementDate);
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper 
            lastUrl="/phone" 
            nextUrl="/offer" 
            isLoading={loading}
            handleNext={() => dispatch(updateSettlementDate(settlementDate))}
            advanceDisabled={!settlementDateCheck.isOk}
        >
            <OneColumnLeft 
                title="When did you settle on your home?" 
                mobileTitle="When did you settle on your home?"
                description="This will make sure that you get the full period for your termite program"
                mobileDescription="This will make sure that you get the full period for your termite program"
            >
                <TextInput value={settlementDate} onChange={(e) => dispatch(setSettlementDate(e.target.value))} type="date" />
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default SettlementPage;