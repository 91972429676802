import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import TwoColumn from "../components/layout/TwoColumn";
import map from '../assets/map.jpg'
import useReloadAccount from "../redux/useReloadAccount";
import { useSelector } from "react-redux";

function InformationPageTwo() {
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper headline="Termite Program Registration" hideBottom={true} isLoading={loading}>
            <TwoColumn
                imageUrl={map}
                title="How we prevent termites"
                buttonText="Next"
                buttonUrl="/info-text-3"
                orientation="right"
            >
                <p className="mb-4">Our DC Metro area unfortunately ranks as the second highest region in the country for termite activity. Once inside, these silent destroyers start feasting on the wood in your home. If not detected early or prevented from entering, they can inflict damage worth thousands of dollars to your home's structure. The treatment required to eliminate them can also be a significant expense.</p>
                <p>Did you know? Termites cause an astounding $5 billion in damage to homes each year in the USA. To make matters worse, most homeowners' insurance programs do not cover this kind of damage. That's why it's vital to take early action in protecting your new home. Start early, keep your home protected!</p>
            </TwoColumn>
        </MainLayoutWrapper>
    )
}

export default InformationPageTwo