/* global google */
import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

const AddressSearchInput = ({ setAddress, value = "", onChange = () => { }, size="normal", placeholder="", type="text" }) => {
    const inputRef = useRef();

    let styles = "border-b-2 border-gray-400 bg-black bg-opacity-40 text-white placeholder-grey-300 focus:outline-none px-3";

    if (type === "date") {
        styles = "border-b-2 border-gray-600 bg-white bg-opacity-40 text-black placeholder-grey-300 focus:outline-none px-3 filter-invert";  // Add the filter-invert class to styles if type is date
    }


    useEffect(() => {
        const initAutocomplete = async () => {
            const loader = new Loader({
                apiKey: 'AIzaSyAz4JLCms0liPQOkFmV4fgHhuAlQw-118g',
                version: 'weekly',
                libraries: ['places'],
            });

            await loader.load();

            const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
                types: ['address'],
                componentRestrictions: { country: 'us' },
            });

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                const addressComponents = place.address_components;
            
                const street_number = addressComponents.find(
                    (component) => component.types.includes('street_number')
                )?.long_name || '';
                const route = addressComponents.find((component) => component.types.includes('route'))?.long_name || '';
                const locality = addressComponents.find((component) => component.types.includes('locality'))?.long_name || '';
                const administrative_area_level_1 = addressComponents.find((component) =>
                    component.types.includes('administrative_area_level_1')
                )?.short_name || '';
                const postal_code = addressComponents.find((component) => component.types.includes('postal_code'))?.long_name || '';
            
                const fullAddress = `${street_number} ${route} ${locality}, ${administrative_area_level_1} ${postal_code}`;
            
                setAddress(fullAddress);
            });
            
        };

        if (!window.google) {
            initAutocomplete();
        }
    }, []);

    if(size == 'normal')  {
        return (
            <input
                ref={inputRef}
                type={type}
                className={styles + ' text-input'}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                onFocus={(e) => e.target.select()}
                autoComplete='nope'
            />
        );
    } else {
        return (
            <input
                ref={inputRef}
                type={type}
                className={`${size} ${styles}`}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                onFocus={(e) => e.target.select()}
                autoComplete='nope'
            />
        )
    }
};

export default AddressSearchInput;
