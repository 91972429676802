import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import VideoPage from "../components/layout/VideoPage";
import useReloadAccount from "../redux/useReloadAccount";
import { useSelector } from "react-redux";

function VideoExplainationPage() {
    useReloadAccount('/account-search', true, 'Account not found. Please try again. Make sure the number you are using is a 6 digit number with no spaces.');
    const loading = useSelector(state => state.account.isAccountLoading)

    return (
        <MainLayoutWrapper headline="Termite Program Registration" nextUrl="/questions" isLoading={loading}>
            <VideoPage src="https://www.loom.com/embed/b6d7fd972fc846c487d06b3d46dd068f?sid=29d87381-a5cf-4f18-ac92-08fdb548d1f3" />
        </MainLayoutWrapper>
    )
}

export default VideoExplainationPage