import { configureStore } from '@reduxjs/toolkit';
import accountSlice from './accountSlice';
import sessionSlice from './sessionSlice';
import formSlice from './formSlice';
import contactSlice from './contactSlice';

export default configureStore({
    reducer: {
        account: accountSlice,
        session: sessionSlice,
        form: formSlice,
        contact: contactSlice,
    },
});