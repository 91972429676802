import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import config from "../config";

export const sendContactRequest = createAsyncThunk(
    "contact/sendContactRequest",
    async (contactInfo) => {
        const csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken')).split('=')[1];
        const response = await fetch(
            `${config.server}/api/contact/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken,
                },
                body: JSON.stringify({
                    'first_name': contactInfo.firstName,
                    'last_name': contactInfo.lastName,
                    'email': contactInfo.email,
                    'phone': contactInfo.phone,
                    'address': contactInfo.address,
                    'city': contactInfo.city,
                    'state': contactInfo.state,
                    'zip': contactInfo.zip,
                }),
                credentials: "include",
            }
        );

        if (!response.ok) {
            throw new Error("Something went wrong");
        } else {
            const data = await response.json();
            return data;
        }
    }
);

const contactSlice = createSlice({
    name: "contact",
    initialState: {
        contactFirstName: "",
        contactLastName: "",
        contactEmail: "",
        contactPhone: "",
        contactAddress: "",
        contactCity: "",
        contactState: "",
        contactZip: "",
    },
    reducers: {
        setContactFirstName: (state, action) => {
            state.contactFirstName = action.payload;
        },
        setContactLastName: (state, action) => {
            state.contactLastName = action.payload;
        },
        setContactEmail: (state, action) => {
            state.contactEmail = action.payload;
        },
        setContactPhone: (state, action) => {
            state.contactPhone = action.payload;
        },
        setContactAddress: (state, action) => {
            state.contactAddress = action.payload;
        },
        setContactCity: (state, action) => {
            state.contactCity = action.payload;
        },
        setContactState: (state, action) => {
            state.contactState = action.payload;
        },
        setContactZip: (state, action) => {
            state.contactZip = action.payload;
        },
    },
    extraReducers: {
        [sendContactRequest.fulfilled]: (state, action) => {
            state.contactFirstName = "";
            state.contactLastName = "";
            state.contactEmail = "";
            state.contactPhone = "";
            state.contactAddress = "";
            state.contactCity = "";
            state.contactState = "";
            state.contactZip = "";
        },
        [sendContactRequest.rejected]: (state, action) => {
            console.log(action.error.message);
        },
    }
});

export const { setContactFirstName, setContactLastName, setContactEmail, setContactPhone, setContactAddress, setContactCity, setContactState, setContactZip } = contactSlice.actions;
export const selectContactFirstName = (state) => state.contact.contactFirstName;
export const selectContactLastName = (state) => state.contact.contactLastName;
export const selectContactEmail = (state) => state.contact.contactEmail;
export const selectContactPhone = (state) => state.contact.contactPhone;
export const selectContactAddress = (state) => state.contact.contactAddress;
export const selectContactCity = (state) => state.contact.contactCity;
export const selectContactState = (state) => state.contact.contactState;
export const selectContactZip = (state) => state.contact.contactZip;
export const selectIsFormComplete = (state) => {
    return state.contact.contactFirstName !== "" &&
        state.contact.contactLastName !== "" &&
        state.contact.contactEmail !== "" &&
        state.contact.contactPhone !== "" &&
        state.contact.contactAddress !== "" &&
        state.contact.contactCity !== "" &&
        state.contact.contactState !== "" &&
        state.contact.contactZip !== "";
}
export default contactSlice.reducer;