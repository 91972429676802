export default function checkIsValidEmail(email) {
    // Basic Email Validation using Regular Expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
        return {isOk: false, msg: "It looks like there might be a typo in your email. Please make sure you entered the correct email address."};
    }

    // Avoid common placeholders
    const commonPlaceholders = ["test@test.com", "example@example.com"];
    if (commonPlaceholders.includes(email.toLowerCase())) {
        return {isOk: false, msg: "Please use a real email address, not a placeholder."};
    }

    // Check for spaces
    if (email.includes(" ")) {
        return {isOk: false, msg: "Email should not contain spaces."};
    }

    // Length check
    if (email.length < 5) {
        return {isOk: false, msg: "Email seems too short."};
    }

    if (email.length > 254) { // maximum length as per RFC
        return {isOk: false, msg: "Email should be less than 254 characters."};
    }

    // Splitting email into username and domain
    const [username, domain] = email.split("@");
    
    // Checking for valid domain
    if (domain.split(".").length < 2) {
        return {isOk: false, msg: "Invalid domain in email."};
    }

    // Ensure domain doesn't have consecutive periods or starts/ends with a period
    if (domain.includes("..") || domain.startsWith(".") || domain.endsWith(".")) {
        return {isOk: false, msg: "Invalid domain format."};
    }

    // Check for reasonable TLDs (can be extended)
    const validTLDs = ["com", "net", "org", "edu", "gov", "uk", "co.uk", "ca", "co.ca", "au", "co.au", "nz", "co.nz", "us", "co.us", "biz", "info", "io", "co", "me", "mobi", "name", "tv", "cc", "ws", "bz", "mn", "eu", "in", "co.in", "net.in", "org.in", "firm.in", "gen.in", "ind.in", "cn", "com.cn", "net.cn", "org.cn", "de", "es", "com.es", "nom.es", "org.es", "gob.es", "edu.es", "se", "nu", "jp", "tw", "com.tw", "idv.tw", "org.tw", "hk", "co.hk", "com.hk", "net.hk", "org.hk", "kr", "co.kr", "ne.kr", "or.kr", "pe", "com.pe", "net.pe", "org.pe", "nom.pe", "sg", "com.sg", "net.sg", "org.sg", "gov.sg", "edu.sg", "fr", "re", "tf", "wf"]
    const tld = domain.split(".").pop();
    if (!validTLDs.includes(tld)) {
        return {isOk: false, msg: "Invalid email address ending. Did you mean: .com, .net, .org, .edu, or .gov?"};
    }

    // double dot
    if (email.includes("..")) {
        return {isOk: false, msg: "Email should not contain consecutive dots."};
    }

    return {isOk: true, msg: ""};
}
