export default function checkIsValidName(name) {
    // it must have at least 2 words
    if (name.trim().split(" ").length < 2) {
        return {isOk: false, msg: "Please enter your first AND last name."};
    }

    if (name.length > 100) {
        return {isOk: false, msg: "Name must be less than 100 characters long."};
    }

    return {isOk: true, msg: ""};
}