import Check from "../../assets/check"
import XMark from "../../assets/xMark"
import PriceTableFeature from "./PriceTableFeature"

function PriceTableColumn({ programName = "", price = "", priceDescription = "", headerColor="#9B9B99", subeaderColor="#E4E4E4", includes = [], doesNotInclude = [], includeDollarSign = true }) {
    return (
        <div style={{ width: '325px' }} className="text-black rounded-sm">
            <div className="text-center py-2 rounded-t-sm font-light" style={{ backgroundColor: headerColor }}>
                {programName}
            </div>
            <div style={{ backgroundColor: subeaderColor }} className="text-center py-4">
                <div className="flex w-full justify-center gap-x-1 font-bold">
                    {includeDollarSign && <p className="text-sm">$</p>}
                    <p className="text-2xl">{price}</p>
                </div>
                <p className="font-light italic text-sm">{priceDescription}</p>
            </div>
            <div style={{ backgroundColor: "#FFFCFC" }} className="flex flex-col pt-2 pb-5">
                {includes.map((item, index) => {
                    const isLast = doesNotInclude.length === 0 && index === includes.length - 1
                    return (
                        <PriceTableFeature icon={<Check />} index={index} key={index} isLast={isLast}>{item}</PriceTableFeature>
                    )
                })}
                {doesNotInclude.map((item, index) => {
                    const isLast = index === doesNotInclude.length - 1
                    return (
                        <PriceTableFeature icon={<XMark />} index={index} key={index} isLast={isLast}>{item}</PriceTableFeature>
                    )
                })}
            </div>
        </div>
    )
}

export default PriceTableColumn