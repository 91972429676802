import MainLayoutWrapper from "../components/layout/MainLayoutWrapper";
import OneColumnCentered from "../components/layout/OneColumnCentered";
import ConfettiExplosion from 'react-confetti-explosion';

function CompleteMaybePage() {
    return (
        <MainLayoutWrapper isLoading={false}>
            <div className="flex justify-center">
                <ConfettiExplosion width="1500" duration={2500} particleCount={500} />
            </div>
            <OneColumnCentered 
                title="Registration Complete! Welcome Aboard"
                mobileTitle="Registration Complete!"
                description="Congrats! Your termite program registration is successful. Keep an eye on your inbox for a confirmation email, and anticipate a friendly welcome call from our team soon. We'll hold onto that special offer for another 14 days. In the meantime, if anything pops up, let us know!"
                buttonUrl={"https://bettertermite.com"}
                buttonText={"Go To Main Site"}
            >
            </OneColumnCentered>
        </MainLayoutWrapper>
    )
}

export default CompleteMaybePage