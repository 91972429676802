function PriceTableFeature({ icon, index, children, isLast = false }) {
    // create a lightgrey bottom border for all but the last feature
    const borderStyle = isLast ? "" : "border-b-2 border-lightGrey"
    return (
        <div key={index} className={`flex px-3 gap-x-3 py-2 ${borderStyle}`}>
            <div>
                {icon}
            </div>
            <p style={{ fontSize: '15px' }}>{children}</p>
        </div>
    )
}

export default PriceTableFeature