function TextArea({ value = "", onChange = () => { } }) {
    return (
        <textarea
            className="w-full h-[150px] border-b-2 border-gray-400 bg-black bg-opacity-40 text-white placeholder-white focus:outline-none px-3 py-4"
            value={value}
            onChange={onChange}
        />
    );
}

export default TextArea;
