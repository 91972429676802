import MainLayoutWrapper from "../components/layout/MainLayoutWrapper"
import OneColumnLeft from "../components/layout/OneColumnLeft"
import SingleSelectContainer from "../components/form/SingleSelectContainer"
import { useDispatch, useSelector } from "react-redux"
import { selectSetupAutoRenew, setSetupAutoRenew, updateSetupAutoRenew } from "../redux/formSlice"

function AutoRenewalPage() {
    const value = useSelector(selectSetupAutoRenew);
    const dispatch = useDispatch();

    return (
        <MainLayoutWrapper 
            headline="Termite Program Registration" 
            lastUrl="/settlement" 
            nextUrl="/offer" 
            isLoading={false}
            handleNext={() => dispatch(updateSetupAutoRenew(value))}
            advanceDisabled={value === null}
        >
            <OneColumnLeft 
                title="Set Up Auto-Renewal for Your Termite Program?" 
                mobileTitle="Want to Setup Autorenewal for Next Year?"
                description="Why not keep your home continuously safeguarded against unexpected termite problems and maintain the protection your builder has already invested in? For just $150/year, your program includes an annual termite inspection and warranty. The best part? If termites show up while your program is active, your treatment is on us! Your first year is already paid for but setting up autopay now, makes sure you don't forget to renew like many homeowners."
                mobileDescription="Keep your home protected. For just $150/year, your program includes an annual termite inspection and warranty. Set up auto-renewal for next year while it is fresh on your mind."
            >
                <SingleSelectContainer
                    selectedValue={value}
                    options={[
                        { "value": true, "text": "Yes, make it easy for me!", handleClick: () => dispatch(setSetupAutoRenew(true)) },
                        { "value": false, "text": "No thanks, I'll prefer a mailed renewal.", handleClick: () => dispatch(setSetupAutoRenew(false)) },
                    ]}
                />
            </OneColumnLeft>
        </MainLayoutWrapper>
    )
}

export default AutoRenewalPage