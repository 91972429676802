import { useMediaQuery } from "react-responsive"

function VideoPage({ src }) {
    const isMobile = useMediaQuery({ query: '(max-width: 760px)' })
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })

    const desktopStyles = { width: '80%'}
    const tabletStyles = { width: '100%'}


    return (
        <div className="w-full h-full flex justify-center items-center pt-36 md:pt-36">
            {!isMobile && (<div className="flex justify-center items-center w-full h-full lg:w-4/5 lg:h-full" style={isTablet ? tabletStyles : desktopStyles}>
                <iframe src="https://www.youtube.com/embed/pMzfDebUbb8?si=OoYZCIfSetpsFQTj" style={{width: '100%', height: '100%'}} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>)}
            {isMobile && (<div className="flex justify-center items-center w-full h-72">
                <iframe src="https://www.youtube.com/embed/pMzfDebUbb8?si=OoYZCIfSetpsFQTj" style={{ width: '100%', height: '100%'}} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>)}
        </div>
    )
}

export default VideoPage