export default function checkValidAccountNum(num) {
    num = num.replace(/\s/g, '');
    // ensure there is exactly 6 digits
    if (num.length !== 6) {
        return {isOk: false, msg: "All account numbers are six digits long. Please make sure you entered the correct number (and not your warranty ID by mistake)."};
    }

    // ensure all characters are digits
    for (let i = 0; i < num.length; i++) {
        if (isNaN(parseInt(num[i]))) {
            return {isOk: false, msg: "Your account number should only contain digits. Are you sure you entered the correct number?"};
        }
    }

    // ensure that the number is between 100000 and 300000
    if (parseInt(num) < 100000 || parseInt(num) > 300000) {
        return {isOk: false, msg: "All account numbers are between 100000 and 300000. Are you sure you entered the correct number?"};
    }

    return {isOk: true, msg: ""};
}